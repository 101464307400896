import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ItemCard from "../components/ItemCard";
import { connect } from "react-redux";
import setPageTitle from "../functions/setTitle";
import * as consts from "../consts/Consts";

function Search(props) {
  useEffect(() => {
    const title = setPageTitle(props.language, "search");
    document.title = title;
  }, [props.language]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialKey = searchParams.get("key");

  const apiToken = "3GhRJi7Z4uBvTnf2yQPcM5Ys8UeAxLk0WdFVX9vNwQ6zPbKuV";

  const [key, setKey] = useState(initialKey);

  const apiUrlSearch = `${consts.API_URL}/search?key=${key}`;
  const [products, setProducts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const headers = {
      "api-token": apiToken,
      "Accept-Language": props.language,
      "Content-Type": "application/json",
    };

    if (key) {
      setIsLoading(true);
      fetch(apiUrlSearch, {
        method: "GET",
        headers: headers,
      })
        .then((response) => response.json())
        .then((data) => {
          const filteredItems = data.data.filter((item) => item.status === 1);
          setProducts(filteredItems);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Ошибка при получении данных:", error);
          setIsLoading(false);
        });
    }
  }, [key, props.language]);

  const handleInputChange = (e) => {
    setKey(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = `/search?key=${key}`;
  };

  return (
    <div className="wrapper">
      <div className="search">
        <div className="container pt-100">
          <div className="search__wrapper wrapper-page">
            <div className="search__top">
              <h2 className="search__title">
                {key.length > 0
                  ? props.language === "az"
                    ? ` "${key}" üçün axtarışınız aşağıdakılar tapıldı:`
                    : props.language === "en"
                    ? ` Your search for "${key}" revealed the following:`
                    : props.language === "ru"
                    ? ` Ваш поиск по запросу "${key}" выявил следующее:`
                    : ""
                  : props.language === "az"
                  ? "Açar sözü yazın"
                  : props.language === "en"
                  ? "Enter search term"
                  : props.language === "ru"
                  ? "Введите ключевое слово"
                  : ""}
              </h2>

              <form onSubmit={handleSubmit}>
                <div className="search__input">
                  <input
                    type="text"
                    name=""
                    id=""
                    value={key}
                    onChange={handleInputChange}
                    placeholder={
                      props.language === "az"
                        ? "Məhsulun adını daxil edin"
                        : props.language === "en"
                        ? "Search something"
                        : props.language === "ru"
                        ? "Введите название продукта"
                        : ""
                    }
                  />
                  <button type="submit">
                    <span className="icon-search"></span>
                  </button>
                </div>
              </form>
            </div>
            <div className="search__result">
              {isLoading ? (
                <div className="search__loading">Loading...</div>
              ) : key.length > 0 ? (
                <div className="search__row row-3">
                  {products ? (
                    products.length > 0 ? (
                      products.map((product) => {
                        if (product.status === 1) {
                          return (
                            <ItemCard
                              key={product.id}
                              item={product}
                              data_type="product"
                            />
                          );
                        } else {
                          return (
                            <div className="search__null" key={product.id}>
                              {props.language === "az"
                                ? "Məhsul tapılmadı!"
                                : props.language === "en"
                                ? "No product found!"
                                : props.language === "ru"
                                ? "Продукт не найден!"
                                : ""}
                            </div>
                          );
                        }
                      })
                    ) : (
                      <div className="search__null">
                        {props.language === "az"
                          ? "Məhsul tapılmadı!"
                          : props.language === "en"
                          ? "No product found!"
                          : props.language === "ru"
                          ? "Продукт не найден!"
                          : ""}
                      </div>
                    )
                  ) : (
                    <div className="search__null">
                      {props.language === "az"
                        ? "Məhsul tapılmadı!"
                        : props.language === "en"
                        ? "No product found!"
                        : props.language === "ru"
                        ? "Продукт не найден!"
                        : ""}
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const t = (a) => a;
export default connect(t)(Search);
