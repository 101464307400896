import React, { useState, useEffect } from "react";
import MyBreadcrumb from "../components/MyBreadcrumb";
import { connect } from "react-redux";
import * as fetchs from "../functions/Fetchs";
import setPageTitle from "../functions/setTitle";

function ContactUs(props) {
  useEffect(() => {
    const title = setPageTitle(props.language, "contact");
    document.title = title;
  }, [props.language]);
  const pathsPageContact = [
    props.language === "az"
      ? { name: "Bizimlə əlaqə", link: "/contact" }
      : props.language === "en"
      ? { name: "Contact us", link: "/contact" }
      : props.language === "ru"
      ? { name: "Связь с нами", link: "/contact" }
      : "",
  ];
  window.localStorage.setItem("language", props.language);

  const contactData = fetchs.useContactFetch(props.language);

  return (
    <>
      <div className="wrapper">
        <div className="contact-page pt-100">
          <MyBreadcrumb paths={pathsPageContact} />
          <div className="container">
            {contactData && (
              <div className="contact-page__wrapper wrapper-page row-2">
                <div className="contact-page__col col-2">
                  <div className="contact-page__item">
                    <div className="contact-page__img">
                      <img src={contactData.image} alt="" />
                    </div>
                  </div>
                </div>
                <div className="contact-page__col col-2">
                  <div className="contact__item">
                    <div className="contact__info text">
                      <>
                        <p>{contactData.title_text}</p>
                        <ul>
                          <li>
                            <span className="icon-location-dot"></span>
                            {props.language === "az"
                              ? `Ünvan: ${contactData.address}`
                              : props.language === "en"
                              ? `Address: ${contactData.address}`
                              : props.language === "ru"
                              ? `Адрес: ${contactData.address}`
                              : ""}
                          </li>

                          <li>
                            <span className="icon-phone"></span>
                            {props.language === "az"
                              ? `Iş rejimi: ${contactData.opening_hours}`
                              : props.language === "en"
                              ? `Opening hours: ${contactData.opening_hours}`
                              : props.language === "ru"
                              ? `Режим работы: ${contactData.opening_hours}`
                              : ""}
                          </li>
                          <li>
                            <span className="icon-phone"></span>
                            {props.language === "az"
                              ? `Email: ${contactData.email}`
                              : props.language === "en"
                              ? `Email: ${contactData.email}`
                              : props.language === "ru"
                              ? `Email: ${contactData.email}`
                              : ""}
                          </li>
                          <li>
                            <span className="icon-phone"></span>
                            {props.language === "az"
                              ? `Telefon: ${contactData.phone}`
                              : props.language === "en"
                              ? `Phone: ${contactData.phone}`
                              : props.language === "ru"
                              ? `Телефон: ${contactData.phone}`
                              : ""}
                          </li>
                        </ul>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const t = (a) => a;
export default connect(t)(ContactUs);
