import React, { useState, useEffect } from "react";
import MyBreadcrumb from "../components/MyBreadcrumb";
import { connect } from "react-redux";
import * as fetchs from "../functions/Fetchs";
import setPageTitle from "../functions/setTitle";

function AboutUs(props) {
  useEffect(() => {
    const title = setPageTitle(props.language, "about");
    document.title = title;
  }, [props.language]);
  const pathsPageAbout = [
    props.language === "az"
      ? { name: "Haqqımızda", link: "/about-us" }
      : props.language === "en"
      ? { name: "About us", link: "/about-us" }
      : props.language === "ru"
      ? { name: "О нас", link: "/about-us" }
      : "",
  ];

  const aboutData = fetchs.useAboutFetch(props.language);

  window.localStorage.setItem("language", props.language);

  return (
    <>
      <div className="wrapper">
        <div className="about-page pt-100">
          <MyBreadcrumb paths={pathsPageAbout} />
          <div className="container">
            {aboutData && (
              <div className="about-page-wrapper row-2 wrapper-page">
                <div className="about-page__col col-2">
                  <div className="about-page__item">
                    <div className="about-page__img">
                      <img src={aboutData.image} alt="" />
                    </div>
                  </div>
                </div>
                <div className="about-page__col col-2">
                  <div className="about-page__item">
                    <div className="about-page__text text">
                      <p
                        dangerouslySetInnerHTML={{ __html: aboutData.text }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const t = (a) => a;
export default connect(t)(AboutUs);
