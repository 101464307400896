import React from "react";
import { Link } from "react-router-dom";

function ItemCardSlider(props) {
  const { item, data_type } = props;

  return (
    item && (
      <div className="product__item">
        <Link
          to={`/${data_type}-details/${item.slug}`}
          className="product__img"
          state={{ slug: item.slug }}
        >
          <img src={item.image} alt={item.name} />
        </Link>
        <Link
          to={`/${data_type}-details/${item.slug}`}
          className="product__name"
          state={{ slug: item.slug }}
        >
          {item.name}
        </Link>
        {item.price !== undefined && item.price !== 0 && (
          <div className="product__price">
            <span className="product__price-new">{item.price} AZN</span>
          </div>
        )}
      </div>
    )
  );
}

export default ItemCardSlider;
