import React from "react";
import { useEffect } from "react";
import MyBreadcrumb from "../components/MyBreadcrumb";
import NewsCard from "../components/NewsCard";
import { connect } from "react-redux";
import setPageTitle from "../functions/setTitle";

function News(props) {
  useEffect(() => {
    const title = setPageTitle(props.language, "news");
    document.title = title;
  }, [props.language]);
  const pathsPageNews = [
    props.language === "az"
      ? { name: "Xəbərlər", link: "/news" }
      : props.language === "en"
      ? { name: "News", link: "/news" }
      : props.language === "ru"
      ? { name: "Новости", link: "/news" }
      : "",
  ];
  window.localStorage.setItem("language", props.language);
  return (
    <>
      <div className="wrapper">
        <div className="news-page pt-100">
          <MyBreadcrumb paths={pathsPageNews} />
          <div className="container">
            <div className="news-page__wrapper wrapper-page">
              <div className="news-page__row row-3 f-w">
                <NewsCard />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const t = (a) => a;
export default connect(t)(News);
