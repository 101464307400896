import { useEffect, useState } from "react";
import * as consts from "../consts/Consts";

const useDataFetch = (language, apiUrl) => {
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const headers = {
      "Accept-Language": language,
      "Content-Type": "application/json",
    };
    fetch(apiUrl, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Сетевой запрос завершился неудачно");
        }
        return response.json();
      })
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          const filteredItems = data.data.filter(
            (item) => item.status === 1 && item.hasOwnProperty("status")
          );
          setFilteredData(filteredItems);
        } else if (data.data) {
          setFilteredData(data.data);
        } else {
          setFilteredData(data);
        }
      });
  }, [language, apiUrl]);
  return filteredData;
};

export default useDataFetch;

export const useAboutFetch = (language) => {
  const apiUrlAbout = `${consts.API_URL}/about`;
  return useDataFetch(language, apiUrlAbout);
};

export const useContactFetch = (language) => {
  const apiUrlContact = `${consts.API_URL}/contact`;
  return useDataFetch(language, apiUrlContact);
};

export const useNewsFetch = (language) => {
  const apiUrlNews = `${consts.API_URL}/news`;
  return useDataFetch(language, apiUrlNews);
};

export const useSliderFetch = (language) => {
  const apiUrlSlider = `${consts.API_URL}/slider`;
  return useDataFetch(language, apiUrlSlider);
};

export const useProductsFetch = (language) => {
  const apiUrlProducts = `${consts.API_URL}/product?limit=8&random=true`;
  return useDataFetch(language, apiUrlProducts);
};

export const useProjectsFetch = (language) => {
  const apiUrlProject = `${consts.API_URL}/project?limit=3&random=true`;
  return useDataFetch(language, apiUrlProject);
};

export const useServicesFetch = (language) => {
  const apiUrlService = `${consts.API_URL}/service?limit=8&random=true`;
  return useDataFetch(language, apiUrlService);
};

export const useSolutionsFetch = (language) => {
  const apiUrlSolution = `${consts.API_URL}/solution?limit=8&random=true`;
  return useDataFetch(language, apiUrlSolution);
};

export const useProductsCategoryFetch = (language) => {
  const apiUrlProductsCategory = `${consts.API_URL}/category/product`;
  return useDataFetch(language, apiUrlProductsCategory);
};

export const useSolutionsCategoryFetch = (language) => {
  const apiUrlSolutionsCategory = `${consts.API_URL}/category/solution`;
  return useDataFetch(language, apiUrlSolutionsCategory);
};

export const useProjectsCategoryFetch = (language) => {
  const apiUrlProjectsCategory = `${consts.API_URL}/category/project`;
  return useDataFetch(language, apiUrlProjectsCategory);
};

export const useServicesCategoryFetch = (language) => {
  const apiUrlServicesCategory = `${consts.API_URL}/category/service`;
  return useDataFetch(language, apiUrlServicesCategory);
};

export const useProductFetch = (language) => {
  const apiUrlProduct = `${consts.API_URL}/product`;
  return useDataFetch(language, apiUrlProduct);
};
