import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as consts from "../consts/Consts";

function NewsCard(props) {
  const [getData, setGetData] = useState(null);
  const apiToken = "3GhRJi7Z4uBvTnf2yQPcM5Ys8UeAxLk0WdFVX9vNwQ6zPbKuV";
  const apiUrl = `${consts.API_URL}/news`;
  window.localStorage.setItem("language", props.language);

  useEffect(() => {
    const headers = {
      "api-token": apiToken,
      "Accept-Language": props.language,
      "Content-Type": "application/json",
    };

    fetch(apiUrl, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.data.filter((news) => news.status === 1);
        setGetData(filteredData);
      });
  }, [props.language]);

  return (
    <>
      {getData &&
        getData.map((news) => (
          <div className="news-page__col col-3" key={news.id}>
            <div className="news-page__item">
              <Link
                to={`/article/${news.slug}`}
                state={{ slug: news.slug }}
                className="news__img"
              >
                <img src={news.image} alt="" />
              </Link>
              <div className="news__body">
                <Link
                  to={`/article/${news.slug}`}
                  state={{ slug: news.slug }}
                  className="news__label label"
                >
                  {news.title}
                </Link>
                <p
                  className="news__text"
                  dangerouslySetInnerHTML={{ __html: news.desc }}
                ></p>
                <Link
                  to={`/article/${news.slug}`}
                  className="news__btn"
                  state={{ slug: news.slug }}
                >
                  <span>
                    {props.language === "az"
                      ? "Ətraflı"
                      : props.language === "en"
                      ? "Read more"
                      : props.language === "ru"
                      ? "Подробнее"
                      : ""}
                  </span>
                  <i className="fa-regular fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        ))}
    </>
  );
}

const t = (a) => a;
export default connect(t)(NewsCard);
