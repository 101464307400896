import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import ItemCard from "../components/ItemCard";
import ItemCardSlider from "../components/ItemCardSlider";
import * as fetchs from "../functions/Fetchs";
import setPageTitle from "../functions/setTitle";

function MainPage(props) {
  useEffect(() => {
    const title = setPageTitle(props.language, "home");
    document.title = title;
  }, [props.language]);

  const [showMoreProducts, setShowMoreProducts] = useState(false);
  window.localStorage.setItem("language", props.language);
  const aboutData = fetchs.useAboutFetch(props.language);
  const contactData = fetchs.useContactFetch(props.language);
  const newsData = fetchs.useNewsFetch(props.language);
  const sliders = fetchs.useSliderFetch(props.language);
  const products = fetchs.useProductsFetch(props.language);
  const projects = fetchs.useProjectsFetch(props.language);
  const services = fetchs.useServicesFetch(props.language);
  const solutions = fetchs.useSolutionsFetch(props.language);

  const handleLoadMore = () => {
    setShowMoreProducts(true);
  };
  return (
    <>
      <div className="wrapper">
        <div className="hero">
          <div className="hero__wrapper">
            <Swiper
              autoplay={{
                delay: 5000,
                disableOnInteraction: true,
              }}
              modules={[Autoplay, Navigation]}
              slidesPerView={1}
              loop={true}
              navigation={true}
              className="hero__swiper"
            >
              {sliders &&
                sliders.map((slider) => {
                  return (
                    <SwiperSlide>
                      {slider.button_url != null ? (
                        <Link to={slider.button_url}>
                          <div
                            className="hero__img"
                            style={{
                              background: `url(${slider.image}) center/cover no-repeat`,
                            }}
                          ></div>
                        </Link>
                      ) : (
                        <div
                          className="hero__img"
                          style={{
                            background: `url(${slider.image}) center/cover no-repeat`,
                          }}
                        ></div>
                      )}
                      <div className="hero__info">
                        {slider.title != undefined && slider.title && (
                          <h3 className="hero__title">{slider.title}</h3>
                        )}
                        {slider.button_url != null &&
                          slider.button_text != null && (
                            <Link
                              to={slider.button_url}
                              className="hero__btn btn-main"
                            >
                              {slider.button_text}
                            </Link>
                          )}
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
        <div className="about pt-100">
          <div className="container">
            {aboutData && (
              <div key={aboutData.id} className="about__wrapper">
                <h2 className="about__title title">
                  {props.language === "az"
                    ? "Haqqımızda"
                    : props.language === "en"
                    ? "About us"
                    : props.language === "ru"
                    ? "О нас"
                    : ""}
                </h2>
                <div className="about__row row-2">
                  <div className="about__col col-2">
                    <div className="about__img">
                      {/* <img src="/img/about.jpg" alt="" /> */}
                      <img src={aboutData.image} alt="" />
                    </div>
                  </div>
                  <div className="about__col col-2">
                    <div className="about__info text">
                      <p>{aboutData.short_text}</p>
                      <div className="btn__read">
                        <Link to="/about-us" className="about__btn btn-main">
                          {props.language === "az"
                            ? "Ətraflı oxu"
                            : props.language === "en"
                            ? "Read more"
                            : props.language === "ru"
                            ? "Подробнее"
                            : ""}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="popular pt-100">
          <div className="container">
            <div className="popular__wrapper">
              <h2 className="popular__title title">
                {" "}
                {props.language === "az"
                  ? "Məhsullar"
                  : props.language === "en"
                  ? "Products"
                  : props.language === "ru"
                  ? "Продукты"
                  : ""}
              </h2>
              <div className="product__row">
                {showMoreProducts
                  ? products &&
                    products.map((product, index) => (
                      <ItemCard
                        key={product.id}
                        item={product}
                        data_type="product"
                      />
                    ))
                  : products &&
                    products
                      .slice(0, 4)
                      .map((product, index) => (
                        <ItemCard
                          key={product.id}
                          item={product}
                          data_type="product"
                        />
                      ))}
              </div>

              {!showMoreProducts && (
                <div className="popular__btn">
                  <button className="loadmore" onClick={handleLoadMore}>
                    {props.language === "az"
                      ? "Daha çox göstər"
                      : props.language === "en"
                      ? "Load more"
                      : props.language === "ru"
                      ? "Показать еще"
                      : ""}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="solution pt-100">
          <div className="container">
            <div className="solution__wrapper">
              <h2 className="solution__title title">
                {props.language === "az"
                  ? "Həll"
                  : props.language === "en"
                  ? "Solution"
                  : props.language === "ru"
                  ? "Решение"
                  : ""}
              </h2>
              <div className="solution__row"></div>
              <Swiper
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: true,
                }}
                modules={[Autoplay, Navigation]}
                loop={true}
                navigation={true}
                breakpoints={{
                  992: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                }}
                className="news__swiper"
              >
                {solutions &&
                  solutions.map((item) => {
                    return (
                      <SwiperSlide>
                        <ItemCardSlider
                          key={item.id}
                          item={item}
                          data_type="solution"
                        />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
        <div className="project pt-100">
          <div className="container">
            <div className="project__wrapper">
              <h2 className="project__title title">
                {props.language === "az"
                  ? "Layihə"
                  : props.language === "en"
                  ? "Project"
                  : props.language === "ru"
                  ? "Проект"
                  : ""}
              </h2>
              <div className="project__row">
                {[
                  projects &&
                    projects.map((project) => (
                      <div className="project__col">
                        <div className="project__item">
                          <Link
                            to={`/project-details/${project.slug}`}
                            state={{ slug: project.slug }}
                            className="project__img"
                          >
                            <img src={project.image} alt={project.name} />
                          </Link>
                          <Link
                            to={`/project-details/${project.slug}`}
                            state={{ slug: project.slug }}
                            className="project__text"
                          >
                            <span className="project__name">
                              {project.name}
                            </span>
                            <span className="icon-chevron-right"></span>
                          </Link>
                        </div>
                      </div>
                    )),
                ]}
              </div>
            </div>
          </div>
        </div>
        <div className="service pt-100">
          <div className="container">
            <div className="service__wrapper">
              <h2 className="service__title title">
                {props.language === "az"
                  ? "Xidmət"
                  : props.language === "en"
                  ? "Service"
                  : props.language === "ru"
                  ? "Сервис"
                  : ""}
              </h2>
              <Swiper
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: true,
                }}
                modules={[Autoplay, Navigation]}
                loop={true}
                navigation={true}
                breakpoints={{
                  992: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                }}
                className="news__swiper"
              >
                {services &&
                  services.map((item) => {
                    return (
                      <SwiperSlide>
                        <ItemCardSlider
                          key={item.id}
                          item={item}
                          data_type="service"
                        />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
        <div className="news pt-100">
          <div className="container">
            <div className="news__wrapper">
              <h2 className="about__title title">
                {props.language === "az"
                  ? "Xəbərlər"
                  : props.language === "en"
                  ? "News"
                  : props.language === "ru"
                  ? "Новости"
                  : ""}
              </h2>
              <Swiper
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: true,
                }}
                modules={[Autoplay, Navigation]}
                loop={true}
                navigation={true}
                breakpoints={{
                  // Когда ширина экрана больше или равна 992px
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  // Когда ширина экрана больше или равна 768px
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  // Когда ширина экрана меньше 768px
                  480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                }}
                className="news__swiper"
              >
                {newsData &&
                  newsData.map((news) => {
                    return (
                      <SwiperSlide key={news.id}>
                        <div className="news-page__item">
                          <Link
                            to={`/article/${news.slug}`}
                            state={{ slug: news.slug }}
                            className="news__img"
                          >
                            <img src={news.image} alt="" />
                          </Link>
                          <div className="news__body">
                            <Link
                              to={`/article/${news.slug}`}
                              state={{ slug: news.slug }}
                              className="news__label label"
                            >
                              {news.title}
                            </Link>
                            <p
                              className="news__text"
                              dangerouslySetInnerHTML={{ __html: news.desc }}
                            ></p>
                            <Link
                              to={`/article/${news.slug}`}
                              className="news__btn"
                              state={{ slug: news.slug }}
                            >
                              <span>
                                {props.language === "az"
                                  ? "Ətraflı"
                                  : props.language === "en"
                                  ? "Read more"
                                  : props.language === "ru"
                                  ? "Подробнее"
                                  : ""}
                              </span>
                              <i className="fa-regular fa-arrow-right"></i>
                            </Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
        <div className="contact pt-100">
          <div className="container">
            {contactData && (
              <div className="contact__wrapper">
                <h2 className="contact__title title">
                  {props.language === "az"
                    ? "Bizimlə əlaqə"
                    : props.language === "en"
                    ? "Contact us"
                    : props.language === "ru"
                    ? "Связь с нами"
                    : ""}
                </h2>
                <div className="contact__row row-2">
                  <div className="contact__col col-2">
                    <div className="contact__img">
                      <img src="/img/about.jpg" alt="" />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="contact__info text">
                      <p>{contactData.title_text}</p>
                      <ul>
                        <li>
                          <span className="icon-location-dot"></span>
                          {props.language === "az"
                            ? `Ünvan: ${contactData.address}`
                            : props.language === "en"
                            ? `Address: ${contactData.address}`
                            : props.language === "ru"
                            ? `Адрес: ${contactData.address}`
                            : ""}
                        </li>

                        <li>
                          <span className="icon-phone"></span>
                          {props.language === "az"
                            ? `Iş rejimi: ${contactData.opening_hours}`
                            : props.language === "en"
                            ? `Opening hours: ${contactData.opening_hours}`
                            : props.language === "ru"
                            ? `Режим работы: ${contactData.opening_hours}`
                            : ""}
                        </li>
                        <li>
                          <span className="icon-phone"></span>
                          {props.language === "az"
                            ? `Email: ${contactData.email}`
                            : props.language === "en"
                            ? `Email: ${contactData.email}`
                            : props.language === "ru"
                            ? `Email: ${contactData.email}`
                            : ""}
                        </li>
                        <li>
                          <span className="icon-phone"></span>
                          {props.language === "az"
                            ? `Telefon: ${contactData.phone}`
                            : props.language === "en"
                            ? `Phone: ${contactData.phone}`
                            : props.language === "ru"
                            ? `Телефон: ${contactData.phone}`
                            : ""}
                        </li>
                      </ul>
                    </div>
                    <div className="btn__read">
                      <Link to="/contact" className="about__btn btn-main">
                        {props.language === "az"
                          ? "Ətraflı oxu"
                          : props.language === "en"
                          ? "Read more"
                          : props.language === "ru"
                          ? "Подробнее"
                          : ""}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const t = (a) => a;
export default connect(t)(MainPage);
