const setPageTitle = (language, page, detailsName) => {
  switch (page) {
    case "home":
      switch (language) {
        case "az":
          return "Ana səhifə";
        case "en":
          return "Home Page";
        case "ru":
          return "Главная страница";
        default:
          return "";
      }
    case "news":
      switch (language) {
        case "az":
          return "Xəbərlər";
        case "en":
          return "News";
        case "ru":
          return "Новости";
        default:
          return "";
      }
    case "article":
      switch (language) {
        case "az":
          return "Ana səhifə";
        case "en":
          return "Home Page";
        case "ru":
          return "Главная страница";
        default:
          return "";
      }
    case "about":
      switch (language) {
        case "az":
          return "Haqqımızda";
        case "en":
          return "About Us";
        case "ru":
          return "О нас";
        default:
          return "";
      }
    case "contact":
      switch (language) {
        case "az":
          return "Əlaqə";
        case "en":
          return "Contact Us";
        case "ru":
          return "Свяжитесь с нами";
        default:
          return "";
      }
    case "products":
      switch (language) {
        case "az":
          return "Məhsullar";
        case "en":
          return "Products";
        case "ru":
          return "Продукты";
        default:
          return "";
      }
    case "solutions":
      switch (language) {
        case "az":
          return "Həllər";
        case "en":
          return "Solutions";
        case "ru":
          return "Решения";
        default:
          return "";
      }
    case "services":
      switch (language) {
        case "az":
          return "Xidmətlər";
        case "en":
          return "Services";
        case "ru":
          return "Сервисы";
        default:
          return "";
      }
    case "projects":
      switch (language) {
        case "az":
          return "Layihələr";
        case "en":
          return "Projects";
        case "ru":
          return "Проекты";
        default:
          return "";
      }
    case "search":
      switch (language) {
        case "az":
          return "Axtarış";
        case "en":
          return "Search";
        case "ru":
          return "Поиск";
        default:
          return "";
      }
    case "details":
      return detailsName;
    default:
      return "";
  }
};

export default setPageTitle;
