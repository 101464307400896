import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function ItemCard(props) {
  const { item, data_type, col } = props;

  return (
    <div
      className={col === "3" ? "product__col card-3" : "product__col card-4"}
      key={item.id}
    >
      <div className="product__item">
        <Link
          to={`/${data_type}-details/${item.slug}`}
          className="product__img"
          state={{ slug: item.slug }}
        >
          <img src={item.image} alt={item.name} />
        </Link>
        <div className="product__body">
          <Link
            to={`/${data_type}-details/${item.slug}`}
            className="product__name"
            state={{ slug: item.slug }}
          >
            {item.name}
          </Link>
          {item.price !== undefined && item.price !== 0 && (
            <div className="product__price">
              <span className="product__price-new">{item.price} AZN</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedSubCategory: state.selectedSubCategory,
    language: state.language,
  };
};

export default connect(mapStateToProps)(ItemCard);
