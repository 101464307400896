import React, { useState, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import * as fetchs from "../functions/Fetchs";

function AccordionMenuItem({ title, children }) {
  const [accordionActive, setAccordionActive] = useState(false);

  const handleAccordionToggle = () => {
    setAccordionActive((prevState) => !prevState);
  };

  return (
    <li className="opened-mobile">
      <div onClick={handleAccordionToggle} className="accordion-header">
        {title}
        <span
          className={`icon-chevron-down ${accordionActive ? "active" : ""}`}
        ></span>
      </div>
      <Collapse in={accordionActive}>
        <ul className="dropdown-menu-mobile1">{children}</ul>
      </Collapse>
    </li>
  );
}

function AccordionSubMenu({ title, children }) {
  const [nestedAccordionActive, setNestedAccordionActive] = useState(false);

  const handleNestedAccordionToggle = () => {
    setNestedAccordionActive((prevState) => !prevState);
  };

  return (
    <li>
      <div
        onClick={handleNestedAccordionToggle}
        className="accordion-header-sub"
      >
        <div className="accordion-header-head">
          {title}
          <span
            className={`icon-chevron-down ${
              nestedAccordionActive ? "active" : ""
            }`}
          ></span>
        </div>
        <Collapse in={nestedAccordionActive}>
          <ul className="dropdown-menu-mobile2">{children}</ul>
        </Collapse>
      </div>
    </li>
  );
}

function MobileMenu(props) {
  const productCategory = fetchs.useProductsCategoryFetch(props.language);
  const solutionCategory = fetchs.useSolutionsCategoryFetch(props.language);
  const projectCategory = fetchs.useProjectsCategoryFetch(props.language);
  const serviceCategory = fetchs.useServicesCategoryFetch(props.language);
  useEffect(() => {
    if (props.mobileMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [props.mobileMenu]);

  const handleSubcategoryChange = (subcategory) => {
    const parentCategory = productCategory.find((category) => {
      return (
        category.sub && category.sub.some((sub) => sub.id === subcategory.id)
      );
    });

    if (parentCategory) {
      props.dispatch({
        type: "SELECT_SUBCATEGORY",
        payload: subcategory,
      });
      props.dispatch({
        type: "SELECT_CATEGORY",
        payload: parentCategory,
      });
    }
  };

  const [searchText, setSearchText] = useState("");

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div
      className={props.mobileMenu ? "mobile active" : "mobile"}
      onClick={() =>
        props.dispatch({
          type: "MOBILE_MENU",
          payload: false,
        })
      }
    >
      <div className="mobile__wrapper" onClick={(e) => e.stopPropagation()}>
        <div
          className="mobile__close"
          onClick={() =>
            props.dispatch({
              type: "MOBILE_MENU",
              payload: false,
            })
          }
        >
          &times;
        </div>
        <ul className="mobile__menu">
          <li>
            <NavLink
              to="/"
              onClick={() =>
                props.dispatch({
                  type: "MOBILE_MENU",
                  payload: false,
                })
              }
            >
              {props.language === "az"
                ? "Ana səhifə"
                : props.language === "en"
                ? "Home"
                : props.language === "ru"
                ? "Главная"
                : ""}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about-us"
              onClick={() =>
                props.dispatch({
                  type: "MOBILE_MENU",
                  payload: false,
                })
              }
            >
              {props.language === "az"
                ? "Haqqımızda"
                : props.language === "en"
                ? "About us"
                : props.language === "ru"
                ? "О нас"
                : ""}
            </NavLink>
          </li>

          <AccordionMenuItem
            title={
              props.language === "az"
                ? "Məhsul"
                : props.language === "en"
                ? "Product"
                : props.language === "ru"
                ? "Продукт"
                : ""
            }
          >
            {productCategory &&
              productCategory.map((category) => {
                if (category.status === 1) {
                  const hasActiveSubCategories =
                    category.sub &&
                    category.sub.some(
                      (subcategory) => subcategory.status === 1
                    );

                  return (
                    <React.Fragment key={category.id}>
                      {hasActiveSubCategories ? (
                        <AccordionSubMenu
                          title={
                            <NavLink
                              to={`/product/${category.slug}`}
                              onClick={() => {
                                props.dispatch({
                                  type: "MOBILE_MENU",
                                  payload: false,
                                });
                                if (category && category.id) {
                                  props.dispatch({
                                    type: "SELECT_CATEGORY",
                                    payload: category,
                                  });
                                  if (category.sub && category.sub.length > 0) {
                                    props.dispatch({
                                      type: "SELECT_SUBCATEGORY",
                                      payload: category.sub[0],
                                    });
                                  } else {
                                    props.dispatch({
                                      type: "SELECT_SUBCATEGORY",
                                      payload: category,
                                    });
                                  }
                                }
                              }}
                            >
                              {category.name}
                            </NavLink>
                          }
                          key={category.id}
                        >
                          {category.sub.map((subcategory) => {
                            if (subcategory.status === 1) {
                              return (
                                <li key={subcategory.id}>
                                  <NavLink
                                    to={`/product/${subcategory.slug}`}
                                    state={{ slug: subcategory.slug }}
                                    onClick={() => {
                                      props.dispatch({
                                        type: "MOBILE_MENU",
                                        payload: false,
                                      });
                                      if (category && category.id) {
                                        props.dispatch({
                                          type: "SELECT_CATEGORY",
                                          payload: category,
                                        });

                                        if (
                                          category.sub &&
                                          category.sub.length > 0
                                        ) {
                                          props.dispatch({
                                            type: "SELECT_SUBCATEGORY",
                                            payload: category.sub[0],
                                          });
                                        } else {
                                          props.dispatch({
                                            type: "SELECT_SUBCATEGORY",
                                            payload: category,
                                          });
                                        }
                                      }
                                      if (
                                        category.sub &&
                                        category.sub.length > 0
                                      ) {
                                        handleSubcategoryChange(subcategory);
                                      } else {
                                        handleSubcategoryChange(null);
                                      }
                                    }}
                                  >
                                    {subcategory.name}
                                  </NavLink>
                                </li>
                              );
                            }
                            return null;
                          })}
                        </AccordionSubMenu>
                      ) : (
                        <li key={category.id}>
                          <NavLink
                            to={`/product/${category.slug}`}
                            onClick={() => {
                              props.dispatch({
                                type: "MOBILE_MENU",
                                payload: false,
                              });
                              if (category && category.id) {
                                props.dispatch({
                                  type: "SELECT_CATEGORY",
                                  payload: category,
                                });
                                if (category.sub && category.sub.length > 0) {
                                  props.dispatch({
                                    type: "SELECT_SUBCATEGORY",
                                    payload: category.sub[0],
                                  });
                                } else {
                                  props.dispatch({
                                    type: "SELECT_SUBCATEGORY",
                                    payload: category,
                                  });
                                }
                              }
                            }}
                          >
                            {category.name}
                          </NavLink>
                        </li>
                      )}
                    </React.Fragment>
                  );
                }
                return null;
              })}
          </AccordionMenuItem>

          <AccordionMenuItem
            title={
              props.language === "az"
                ? "Həll"
                : props.language === "en"
                ? "Solution"
                : props.language === "ru"
                ? "Решение"
                : ""
            }
          >
            {solutionCategory &&
              solutionCategory.map((category) => {
                if (category.status === 1) {
                  const hasActiveSubCategories =
                    category.sub &&
                    category.sub.some(
                      (subcategory) => subcategory.status === 1
                    );

                  return (
                    <React.Fragment key={category.id}>
                      {hasActiveSubCategories ? (
                        <AccordionSubMenu
                          title={
                            <NavLink
                              to={`/solution/${category.slug}`}
                              onClick={() => {
                                props.dispatch({
                                  type: "MOBILE_MENU",
                                  payload: false,
                                });
                                if (category && category.id) {
                                  props.dispatch({
                                    type: "SELECT_CATEGORY",
                                    payload: category,
                                  });
                                  if (category.sub && category.sub.length > 0) {
                                    props.dispatch({
                                      type: "SELECT_SUBCATEGORY",
                                      payload: category.sub[0],
                                    });
                                  } else {
                                    props.dispatch({
                                      type: "SELECT_SUBCATEGORY",
                                      payload: category,
                                    });
                                  }
                                }
                              }}
                            >
                              {category.name}
                            </NavLink>
                          }
                          key={category.id}
                        >
                          {category.sub.map((subcategory) => {
                            if (subcategory.status === 1) {
                              return (
                                <li key={subcategory.id}>
                                  <NavLink
                                    to={`/solution/${subcategory.slug}`}
                                    state={{ slug: subcategory.slug }}
                                    onClick={() => {
                                      props.dispatch({
                                        type: "MOBILE_MENU",
                                        payload: false,
                                      });
                                      if (category && category.id) {
                                        props.dispatch({
                                          type: "SELECT_CATEGORY",
                                          payload: category,
                                        });

                                        if (
                                          category.sub &&
                                          category.sub.length > 0
                                        ) {
                                          props.dispatch({
                                            type: "SELECT_SUBCATEGORY",
                                            payload: category.sub[0],
                                          });
                                        } else {
                                          props.dispatch({
                                            type: "SELECT_SUBCATEGORY",
                                            payload: category,
                                          });
                                        }
                                      }
                                      if (
                                        category.sub &&
                                        category.sub.length > 0
                                      ) {
                                        handleSubcategoryChange(subcategory);
                                      } else {
                                        handleSubcategoryChange(null);
                                      }
                                    }}
                                  >
                                    {subcategory.name}
                                  </NavLink>
                                </li>
                              );
                            }
                            return null;
                          })}
                        </AccordionSubMenu>
                      ) : (
                        <li key={category.id}>
                          <NavLink
                            to={`/solution/${category.slug}`}
                            onClick={() => {
                              props.dispatch({
                                type: "MOBILE_MENU",
                                payload: false,
                              });
                              if (category && category.id) {
                                props.dispatch({
                                  type: "SELECT_CATEGORY",
                                  payload: category,
                                });
                                if (category.sub && category.sub.length > 0) {
                                  props.dispatch({
                                    type: "SELECT_SUBCATEGORY",
                                    payload: category.sub[0],
                                  });
                                } else {
                                  props.dispatch({
                                    type: "SELECT_SUBCATEGORY",
                                    payload: category,
                                  });
                                }
                              }
                            }}
                          >
                            {category.name}
                          </NavLink>
                        </li>
                      )}
                    </React.Fragment>
                  );
                }
                return null;
              })}
          </AccordionMenuItem>

          <AccordionMenuItem
            title={
              props.language === "az"
                ? "Layihə"
                : props.language === "en"
                ? "Project"
                : props.language === "ru"
                ? "Проект"
                : ""
            }
          >
            {projectCategory &&
              projectCategory.map((category) => {
                if (category.status === 1) {
                  const hasActiveSubCategories =
                    category.sub &&
                    category.sub.some(
                      (subcategory) => subcategory.status === 1
                    );

                  return (
                    <React.Fragment key={category.id}>
                      {hasActiveSubCategories ? (
                        <AccordionSubMenu
                          title={
                            <NavLink
                              to={`/project/${category.slug}`}
                              onClick={() => {
                                props.dispatch({
                                  type: "MOBILE_MENU",
                                  payload: false,
                                });
                                if (category && category.id) {
                                  props.dispatch({
                                    type: "SELECT_CATEGORY",
                                    payload: category,
                                  });
                                  if (category.sub && category.sub.length > 0) {
                                    props.dispatch({
                                      type: "SELECT_SUBCATEGORY",
                                      payload: category.sub[0],
                                    });
                                  } else {
                                    props.dispatch({
                                      type: "SELECT_SUBCATEGORY",
                                      payload: category,
                                    });
                                  }
                                }
                              }}
                            >
                              {category.name}
                            </NavLink>
                          }
                          key={category.id}
                        >
                          {category.sub.map((subcategory) => {
                            if (subcategory.status === 1) {
                              return (
                                <li key={subcategory.id}>
                                  <NavLink
                                    to={`/project/${subcategory.slug}`}
                                    state={{ slug: subcategory.slug }}
                                    onClick={() => {
                                      props.dispatch({
                                        type: "MOBILE_MENU",
                                        payload: false,
                                      });
                                      if (category && category.id) {
                                        props.dispatch({
                                          type: "SELECT_CATEGORY",
                                          payload: category,
                                        });

                                        if (
                                          category.sub &&
                                          category.sub.length > 0
                                        ) {
                                          props.dispatch({
                                            type: "SELECT_SUBCATEGORY",
                                            payload: category.sub[0],
                                          });
                                        } else {
                                          props.dispatch({
                                            type: "SELECT_SUBCATEGORY",
                                            payload: category,
                                          });
                                        }
                                      }
                                      if (
                                        category.sub &&
                                        category.sub.length > 0
                                      ) {
                                        handleSubcategoryChange(subcategory);
                                      } else {
                                        handleSubcategoryChange(null);
                                      }
                                    }}
                                  >
                                    {subcategory.name}
                                  </NavLink>
                                </li>
                              );
                            }
                            return null;
                          })}
                        </AccordionSubMenu>
                      ) : (
                        <li key={category.id}>
                          <NavLink
                            to={`/project/${category.slug}`}
                            onClick={() => {
                              props.dispatch({
                                type: "MOBILE_MENU",
                                payload: false,
                              });
                              if (category && category.id) {
                                props.dispatch({
                                  type: "SELECT_CATEGORY",
                                  payload: category,
                                });
                                if (category.sub && category.sub.length > 0) {
                                  props.dispatch({
                                    type: "SELECT_SUBCATEGORY",
                                    payload: category.sub[0],
                                  });
                                } else {
                                  props.dispatch({
                                    type: "SELECT_SUBCATEGORY",
                                    payload: category,
                                  });
                                }
                              }
                            }}
                          >
                            {category.name}
                          </NavLink>
                        </li>
                      )}
                    </React.Fragment>
                  );
                }
                return null;
              })}
          </AccordionMenuItem>

          <AccordionMenuItem
            title={
              props.language === "az"
                ? "Xidmət"
                : props.language === "en"
                ? "Service"
                : props.language === "ru"
                ? "Сервис"
                : ""
            }
          >
            {serviceCategory &&
              serviceCategory.map((category) => {
                if (category.status === 1) {
                  const hasActiveSubCategories =
                    category.sub &&
                    category.sub.some(
                      (subcategory) => subcategory.status === 1
                    );

                  return (
                    <React.Fragment key={category.id}>
                      {hasActiveSubCategories ? (
                        <AccordionSubMenu
                          title={
                            <NavLink
                              to={`/service/${category.slug}`}
                              onClick={() => {
                                props.dispatch({
                                  type: "MOBILE_MENU",
                                  payload: false,
                                });
                                if (category && category.id) {
                                  props.dispatch({
                                    type: "SELECT_CATEGORY",
                                    payload: category,
                                  });
                                  if (category.sub && category.sub.length > 0) {
                                    props.dispatch({
                                      type: "SELECT_SUBCATEGORY",
                                      payload: category.sub[0],
                                    });
                                  } else {
                                    props.dispatch({
                                      type: "SELECT_SUBCATEGORY",
                                      payload: category,
                                    });
                                  }
                                }
                              }}
                            >
                              {category.name}
                            </NavLink>
                          }
                          key={category.id}
                        >
                          {category.sub.map((subcategory) => {
                            if (subcategory.status === 1) {
                              return (
                                <li key={subcategory.id}>
                                  <NavLink
                                    to={`/service/${subcategory.slug}`}
                                    state={{ slug: subcategory.slug }}
                                    onClick={() => {
                                      props.dispatch({
                                        type: "MOBILE_MENU",
                                        payload: false,
                                      });
                                      if (category && category.id) {
                                        props.dispatch({
                                          type: "SELECT_CATEGORY",
                                          payload: category,
                                        });

                                        if (
                                          category.sub &&
                                          category.sub.length > 0
                                        ) {
                                          props.dispatch({
                                            type: "SELECT_SUBCATEGORY",
                                            payload: category.sub[0],
                                          });
                                        } else {
                                          props.dispatch({
                                            type: "SELECT_SUBCATEGORY",
                                            payload: category,
                                          });
                                        }
                                      }
                                      if (
                                        category.sub &&
                                        category.sub.length > 0
                                      ) {
                                        handleSubcategoryChange(subcategory);
                                      } else {
                                        handleSubcategoryChange(null);
                                      }
                                    }}
                                  >
                                    {subcategory.name}
                                  </NavLink>
                                </li>
                              );
                            }
                            return null;
                          })}
                        </AccordionSubMenu>
                      ) : (
                        <li key={category.id}>
                          <NavLink
                            to={`/service/${category.slug}`}
                            onClick={() => {
                              props.dispatch({
                                type: "MOBILE_MENU",
                                payload: false,
                              });
                              if (category && category.id) {
                                props.dispatch({
                                  type: "SELECT_CATEGORY",
                                  payload: category,
                                });
                                if (category.sub && category.sub.length > 0) {
                                  props.dispatch({
                                    type: "SELECT_SUBCATEGORY",
                                    payload: category.sub[0],
                                  });
                                } else {
                                  props.dispatch({
                                    type: "SELECT_SUBCATEGORY",
                                    payload: category,
                                  });
                                }
                              }
                            }}
                          >
                            {category.name}
                          </NavLink>
                        </li>
                      )}
                    </React.Fragment>
                  );
                }
                return null;
              })}
          </AccordionMenuItem>

          <li>
            <NavLink
              to="/news"
              onClick={() =>
                props.dispatch({
                  type: "MOBILE_MENU",
                  payload: false,
                })
              }
            >
              {props.language === "az"
                ? "Xəbərlər"
                : props.language === "en"
                ? "News"
                : props.language === "ru"
                ? "Новости"
                : ""}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              onClick={() =>
                props.dispatch({
                  type: "MOBILE_MENU",
                  payload: false,
                })
              }
            >
              {props.language === "az"
                ? "Əlaqə"
                : props.language === "en"
                ? "Contact"
                : props.language === "ru"
                ? "Контакт"
                : ""}
            </NavLink>
          </li>
        </ul>
        <div className="mobile__search">
          <form action={`/search?key=${searchText}`} method="get">
            <input
              type="text"
              name="key"
              id=""
              placeholder={
                props.language === "az"
                  ? "Axtarış"
                  : props.language === "en"
                  ? "Search"
                  : props.language === "ru"
                  ? "Поиск"
                  : ""
              }
              value={searchText}
              onChange={handleSearchInputChange}
            />
            <span className="icon-search"></span>
          </form>
        </div>
      </div>
    </div>
  );
}

const t = (a) => a;
export default connect(t)(MobileMenu);
