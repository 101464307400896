import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MyBreadcrumb from "../components/MyBreadcrumb";
import ItemCard from "../components/ItemCard";
import * as fetchs from "../functions/Fetchs";
import setPageTitle from "../functions/setTitle";
import * as consts from "../consts/Consts";

function CollectionSolution(props) {
  useEffect(() => {
    const title = setPageTitle(props.language, "solutions");
    document.title = title;
  }, [props.language]);
  const pathsPage1 = [
    props.language === "az"
      ? { name: "Solutions" }
      : props.language === "en"
      ? { name: "Solutions" }
      : props.language === "ru"
      ? { name: "Решение" }
      : "",
  ];

  const { selectedSubCategory, language, selectedCategory } = props;

  const apiUrlProductByCategory = selectedSubCategory
    ? `${consts.API_URL}/solution/category/${selectedSubCategory.id}`
    : "";

  const [products, setProducts] = useState([]);

  const categories = fetchs.useSolutionsCategoryFetch(language);

  const fetchProducts = () => {
    const headers = {
      "Accept-Language": language,
      "Content-Type": "application/json",
    };

    fetch(apiUrlProductByCategory, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        const filteredCategories = data.data.filter(
          (category) => category.status === 1
        );
        setProducts(filteredCategories);
      });
  };

  useEffect(() => {
    if (selectedSubCategory) {
      fetchProducts();
    }
  }, [selectedSubCategory, language]);

  const handleCategoryChange = (category) => {
    props.dispatch({
      type: "SELECT_CATEGORY",
      payload: category,
    });

    if (category.sub && category.sub.length > 0) {
      props.dispatch({
        type: "SELECT_SUBCATEGORY",
        payload: category.sub[0],
      });
    } else {
      props.dispatch({
        type: "SELECT_SUBCATEGORY",
        payload: null,
      });
    }
  };

  const handleSubcategoryChange = (subcategory) => {
    if (selectedCategory) {
      props.dispatch({
        type: "SELECT_SUBCATEGORY",
        payload: subcategory,
      });
      props.dispatch({
        type: "SELECT_CATEGORY",
        payload: selectedCategory,
      });
    }
  };

  return (
    <div className="wrapper">
      <div className="collection">
        <MyBreadcrumb paths={pathsPage1} />
        <div className="collection__wrapper">
          <div
            className="tab-top"
            style={{
              display: "flex",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <div className="container">
              <div className="tab-categories">
                {categories &&
                  categories.map((category) => {
                    return (
                      <p
                        className={`tab-category ${
                          category.id === selectedCategory.id ? "active" : ""
                        }`}
                        key={category.id}
                        onClick={() => {
                          handleCategoryChange(category);
                        }}
                      >
                        {category.name}
                      </p>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="tab__container">
              <div
                className="tab__center"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="tab__col tab-subcategory">
                  <div className="tab__subcategory">
                    {selectedCategory &&
                    selectedCategory.sub &&
                    selectedCategory.sub.length > 0 ? (
                      categories.map((category) => {
                        if (category.id === selectedCategory.id) {
                          return (
                            category.sub &&
                            category.sub.map((subcategory) => (
                              <p
                                className={`tab-subcategory ${
                                  selectedSubCategory &&
                                  subcategory.id === selectedSubCategory.id
                                    ? "active"
                                    : ""
                                }`}
                                style={{
                                  cursor: "pointer",
                                }}
                                key={subcategory.id}
                                onClick={() => {
                                  handleSubcategoryChange(subcategory);
                                }}
                              >
                                {subcategory.name}
                              </p>
                            ))
                          );
                        }
                        return null;
                      })
                    ) : (
                      <p>
                        {props.language === "az"
                          ? "Mövcud altkateqoriya yoxdur"
                          : props.language === "en"
                          ? "No available subcategories"
                          : props.language === "ru"
                          ? "Нет доступных субкатегорий"
                          : ""}
                      </p>
                    )}
                  </div>
                </div>
                <div className="tab__col tab-content">
                  <div className="tab__content">
                    <div className="tab__row product__row">
                      {selectedSubCategory !== null && products.length > 0 ? (
                        products.map((product) => (
                          <ItemCard
                            key={product.id}
                            item={product}
                            data_type="solution"
                            col="3"
                          />
                        ))
                      ) : (
                        <p>
                          {props.language === "az"
                            ? "Bu altkateqoriyada heç bir məhsul mövcud deyil"
                            : props.language === "en"
                            ? "No products available in this subcategory"
                            : props.language === "ru"
                            ? "Нет доступных продуктов в этой субкатегории"
                            : ""}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedCategory: state.selectedCategory,
    selectedSubCategory: state.selectedSubCategory,
    language: state.language,
  };
};

export default connect(mapStateToProps)(CollectionSolution);
