import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import MyBreadcrumb from "../components/MyBreadcrumb";
import setPageTitle from "../functions/setTitle";
import * as consts from "../consts/Consts";

function Article(props) {
  const location = useLocation();
  const { slug } = location.state || {};
  const [articleData, setArticleData] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const apiToken = "3GhRJi7Z4uBvTnf2yQPcM5Ys8UeAxLk0WdFVX9vNwQ6zPbKuV";
  const apiUrl = `${consts.API_URL}/news/${slug}`;
  window.localStorage.setItem("language", props.language);

  useEffect(() => {
    const headers = {
      "api-token": apiToken,
      "Accept-Language": props.language,
      "Content-Type": "application/json",
    };

    fetch(apiUrl, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data.status === 1) {
          setArticleData(data.data);
          const pageTitle = setPageTitle(
            props.language,
            "details",
            data.data.title
          );
          document.title = pageTitle;
        } else {
          setNotFound(true);
        }
      })
      .catch((error) => {
        setNotFound(true);
      });
  }, [apiUrl, props.language, slug]);

  const pathsPageArticle = [
    { name: "News", link: "/news" },
    { name: articleData ? articleData.title : "Loading...", link: "/article" },
  ];

  return (
    <>
      <div className="wrapper">
        <div className="article pt-100">
          <MyBreadcrumb paths={pathsPageArticle} />
          <div className="container">
            {notFound ? (
              <div className="not-found-page">
                <div className="not-found">
                  {" "}
                  {props.language === "az"
                    ? "Səhifə tapılmadı"
                    : props.language === "en"
                    ? "Page not found"
                    : props.language === "ru"
                    ? "Страница не найдена"
                    : ""}
                </div>
              </div>
            ) : (
              articleData && (
                <div className="article__wrapper wrapper-page">
                  <div className="article__img">
                    <img src={articleData.image} alt="" />
                  </div>
                  <div
                    className="article__text text"
                    dangerouslySetInnerHTML={{ __html: articleData.desc }}
                  ></div>
                  <div className="article__btn">
                    <Link to="/news">Back to news</Link>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const t = (a) => a;
export default connect(t)(Article);
