if (!window.localStorage.getItem("language")) {
  window.localStorage.setItem("language", "az");
}
const initialState = {
  headerShow: true,
  mobileMenu: false,
  language:
    window.localStorage.getItem("language") ||
    window.localStorage.setItem("language", "az"),
  selectedCategory:
    JSON.parse(window.localStorage.getItem("selectedCategory")) || {},
  selectedSubCategory:
    JSON.parse(window.localStorage.getItem("selectedSubCategory")) || {},
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case "HEADER_SHOW":
      return { ...state, headerShow: action.payload };
    case "MOBILE_MENU":
      return { ...state, mobileMenu: action.payload };
    case "SWITCH_LANGUAGE":
      return { ...state, language: action.payload };
    case "SELECT_CATEGORY":
      window.localStorage.setItem(
        "selectedCategory",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        selectedCategory: action.payload,
      };
    case "SELECT_SUBCATEGORY":
      window.localStorage.setItem(
        "selectedSubCategory",
        JSON.stringify(action.payload)
      );
      return {
        ...state,
        selectedSubCategory: action.payload,
      };
    default:
      return state;
  }
}
