import React, { useState, useEffect } from "react";
import MyBreadcrumb from "../components/MyBreadcrumb";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Carousel } from "react-bootstrap";
import ReactImageZoom from "react-image-zoom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { connect } from "react-redux";
import ItemCardSlider from "../components/ItemCardSlider";
import setPageTitle from "../functions/setTitle";
import * as consts from "../consts/Consts";
import ThumbnailCarousel from "../components/ThumbnailCarousel";

function DetailsProduct(props) {
  window.localStorage.setItem("language", props.language);
  const { slug } = useParams();
  const [details, setDetails] = useState(null);
  const [items, setItems] = useState(null);
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const apiToken = "3GhRJi7Z4uBvTnf2yQPcM5Ys8UeAxLk0WdFVX9vNwQ6zPbKuV";
  const apiUrlDetails = `${consts.API_URL}/product/${slug}`;
  const apiUrlRecents = `${consts.API_URL}/product/recent/${category}`;

  const pathsProduct = [
    {
      name:
        props.language === "az"
          ? "Məhsullar"
          : props.language === "en"
          ? "Products"
          : props.language === "ru"
          ? "Продукты"
          : "",
      link: "/product",
    },
    {
      name: details ? details.name : "Loading...",
    },
  ];

  useEffect(() => {
    const headers = {
      "api-token": apiToken,
      "Accept-Language": props.language,
      "Content-Type": "application/json",
    };

    fetch(apiUrlDetails, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setDetails(data.data);
        setCategory(data.data.product_category_id);
        setIsLoading(false);
        const pageTitle = setPageTitle(
          props.language,
          "details",
          data.data.name
        );
        document.title = pageTitle;
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [apiUrlDetails, props.language, slug]);

  useEffect(() => {
    const headers = {
      "api-token": apiToken,
      "Accept-Language": props.language,
      "Content-Type": "application/json",
    };

    fetch(apiUrlRecents, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        const filteredItems = data.data.filter((item) => item.status === 1);
        setItems(filteredItems);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [apiUrlRecents, props.language, slug, category]);

  const [thumbnailStartIndex, setThumbnailStartIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const prevSlide = () => {
    if (thumbnailStartIndex > 0) {
      setThumbnailStartIndex(thumbnailStartIndex - 1);
      setActiveIndex(activeIndex - 1);
    }
  };

  const nextSlide = () => {
    if (thumbnailStartIndex < details.images.length - 4) {
      setThumbnailStartIndex(thumbnailStartIndex + 1);
      setActiveIndex(activeIndex + 1);
    }
  };

  const imagesArray =
    details && details.images
      ? [details.image, ...details.images.map((img) => img.image)]
      : details
      ? [details.image]
      : [];

  return (
    <div className="wrapper">
      {isLoading ? (
        <p>Loading...</p>
      ) : details && details.status === 0 ? (
        <div className="wrapper">
          <div className="not-found-page">
            <div className="not-found">Not found</div>
          </div>
        </div>
      ) : details && details.status === 1 ? (
        <>
          <div className="details">
            <MyBreadcrumb paths={pathsProduct} />
            <div className="container">
              <div className="details__wrapper wrapper-page">
                <div className="details__top">
                  <div className="details__col details__col-img">
                    <div className="details__img">
                      <div className="image-carousel-wrapper">
                        <div className="carousel-container">
                          {details.images !== null &&
                          Array.isArray(details.images) &&
                          details.images.length > 0 ? (
                            <Carousel
                              activeIndex={activeIndex}
                              onSelect={handleSelect}
                              interval={null}
                            >
                              {imagesArray.map((image, index) => (
                                <Carousel.Item key={index}>
                                  <div className="carousel-image-container">
                                    <img
                                      src={image}
                                      alt={`Image ${index + 1}`}
                                      style={{
                                        width: "468px",
                                        height: "305px",
                                      }}
                                    />
                                  </div>
                                </Carousel.Item>
                              ))}
                            </Carousel>
                          ) : (
                            <div className="alternative-image">
                              <img
                                src={details.image}
                                alt="Alternative Image"
                              />
                            </div>
                          )}
                          <ThumbnailCarousel
                            details={details}
                            activeIndex={activeIndex}
                            thumbnailStartIndex={thumbnailStartIndex}
                            imagesArray={imagesArray}
                            handleSelect={handleSelect}
                            prevSlide={prevSlide}
                            nextSlide={nextSlide}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="details__col details__col-info">
                    <div className="details__item">
                      <div
                        className="details__info"
                        style={!details.pdf ? { width: "100%" } : {}}
                      >
                        {details.price !== undefined && details.price !== 0 && (
                          <div className="details__price">
                            <span>{details.price} AZN</span>
                          </div>
                        )}
                        <div
                          className="details__description"
                          dangerouslySetInnerHTML={{ __html: details.desc }}
                        ></div>
                      </div>
                      {details.pdf && (
                        <div className="details__pdf">
                          <Link to={details.pdf} target="_blank" download>
                            Download PDF
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="details__bottom"></div>
              </div>
            </div>
          </div>

          <div className="related">
            <div className="container">
              <div className="related__wrapper">
                <h3 className="related__title">
                  {props.language === "az"
                    ? "DİGƏR MÜŞTƏRİLƏR DƏ ALIR"
                    : props.language === "en"
                    ? " OTHER CUSTOMERS ALSO BUY"
                    : props.language === "ru"
                    ? "ДРУГИЕ КЛИЕНТЫ ТАКЖЕ ПОКУПАЮТ"
                    : ""}
                </h3>
                <Swiper
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                  }}
                  modules={[Autoplay, Navigation]}
                  loop={true}
                  navigation={true}
                  breakpoints={{
                    1100: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                    // Когда ширина экрана больше или равна 992px
                    992: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                    // Когда ширина экрана больше или равна 768px
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    // Когда ширина экрана меньше 768px
                    480: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                  }}
                  className="mySwiper"
                >
                  {items &&
                    items.map((item) => {
                      return (
                        <SwiperSlide key={item.id}>
                          <ItemCardSlider
                            key={item.id}
                            item={item}
                            data_type="product"
                          />
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="wrapper">
          <div className="not-found-page">
            <div className="not-found">
              {" "}
              {props.language === "az"
                ? "Səhifə tapılmadı"
                : props.language === "en"
                ? "Page not found"
                : props.language === "ru"
                ? "Страница не найдена"
                : ""}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const t = (a) => a;
export default connect(t)(DetailsProduct);
