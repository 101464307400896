import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import MobileMenu from "./MobileMenu";
import * as fetchs from "../functions/Fetchs";

const languages = [
  { id: 1, label: "az", value: "az" },
  { id: 2, label: "en", value: "en" },
  { id: 3, label: "ru", value: "ru" },
];

function Header(props) {
  const [headerSearch, setHeaderSearch] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const handleHeaderSearch = () => {
    setHeaderSearch((prevHeaderSearch) => !prevHeaderSearch);
  };

  const handleToggle = (isOpen) => {
    setShowDropdown(isOpen);
  };

  useEffect(() => {
    const userLanguage =
      window.localStorage.getItem("language") === "undefined"
        ? "az"
        : window.localStorage.getItem("language");
    const initialLanguage = userLanguage;

    const selectedLanguageObj = languages.find(
      (language) => language.value === initialLanguage
    );

    setSelectedLanguage(selectedLanguageObj);
  }, []);

  const handleLanguageSelect = (value) => {
    const selectedLanguageObj = languages.find(
      (language) => language.value === value
    );
    setSelectedLanguage(selectedLanguageObj);
    setShowDropdown(false);
    props.dispatch({
      type: "SWITCH_LANGUAGE",
      payload: value,
    });
  };

  const [searchText, setSearchText] = useState("");

  const productCategory = fetchs.useProductsCategoryFetch(props.language);
  const solutionCategory = fetchs.useSolutionsCategoryFetch(props.language);
  const projectCategory = fetchs.useProjectsCategoryFetch(props.language);
  const serviceCategory = fetchs.useServicesCategoryFetch(props.language);

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSubcategoryChange = (subcategory) => {
    const parentCategory = productCategory.find((category) => {
      return (
        category.sub && category.sub.some((sub) => sub.id === subcategory.id)
      );
    });

    if (parentCategory) {
      props.dispatch({
        type: "SELECT_SUBCATEGORY",
        payload: subcategory,
      });
      props.dispatch({
        type: "SELECT_CATEGORY",
        payload: parentCategory,
      });
    }
  };

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="header__wrapper">
            <NavLink to="/" className="header__logo">
              <img src="/img/logo.png" alt="" />
            </NavLink>
            <ul className="header__menu">
              <li>
                <NavLink to="/">
                  {props.language === "az"
                    ? "Ana səhifə"
                    : props.language === "en"
                    ? "Home"
                    : props.language === "ru"
                    ? "Главная"
                    : ""}
                </NavLink>
              </li>
              <li>
                <NavLink to="/about-us">
                  {props.language === "az"
                    ? "Haqqımızda"
                    : props.language === "en"
                    ? "About us"
                    : props.language === "ru"
                    ? "О нас"
                    : ""}
                </NavLink>
              </li>

              <li className="opened">
                <p>
                  {props.language === "az"
                    ? "Məhsul"
                    : props.language === "en"
                    ? "Product"
                    : props.language === "ru"
                    ? "Продукт"
                    : ""}
                  <span className="icon-chevron-down"></span>
                </p>
                <ul className="dropdown-menu1">
                  {productCategory &&
                    productCategory.map((category) => {
                      if (category.status === 1) {
                        const hasActiveSubCategories =
                          category.sub &&
                          category.sub.some(
                            (subcategory) => subcategory.status === 1
                          );
                        return (
                          <li className="opened-sub" key={category.id}>
                            <NavLink
                              to={`/product/${category.slug}`}
                              onClick={() => {
                                if (category && category.id) {
                                  props.dispatch({
                                    type: "SELECT_CATEGORY",
                                    payload: category,
                                  });

                                  if (category.sub && category.sub.length > 0) {
                                    props.dispatch({
                                      type: "SELECT_SUBCATEGORY",
                                      payload: category.sub[0],
                                    });
                                  } else {
                                    props.dispatch({
                                      type: "SELECT_SUBCATEGORY",
                                      payload: category,
                                    });
                                  }
                                }
                              }}
                            >
                              {category.name}
                              {hasActiveSubCategories ? (
                                <span className="icon-chevron-down"></span>
                              ) : null}
                            </NavLink>
                            {hasActiveSubCategories ? (
                              <ul className="dropdown-menu2">
                                {category.sub.map((subcategory) => {
                                  if (subcategory.status === 1) {
                                    return (
                                      <li key={subcategory.id}>
                                        <NavLink
                                          to={`/product/${subcategory.slug}`}
                                          state={{ slug: subcategory.slug }}
                                          onClick={() => {
                                            if (
                                              category.sub &&
                                              category.sub.length > 0
                                            ) {
                                              handleSubcategoryChange(
                                                subcategory
                                              );
                                            } else {
                                              handleSubcategoryChange(null);
                                            }
                                          }}
                                        >
                                          {subcategory.name}
                                        </NavLink>
                                      </li>
                                    );
                                  }
                                  return null;
                                })}
                              </ul>
                            ) : null}
                          </li>
                        );
                      }
                      return null;
                    })}
                </ul>
              </li>
              <li className="opened">
                <p>
                  {props.language === "az"
                    ? "Həll"
                    : props.language === "en"
                    ? "Solution"
                    : props.language === "ru"
                    ? "Решение"
                    : ""}
                  <span className="icon-chevron-down"></span>
                </p>

                <ul className="dropdown-menu1">
                  {solutionCategory &&
                    solutionCategory.map((category) => {
                      if (category.status === 1) {
                        const hasActiveSubCategories =
                          category.sub &&
                          category.sub.some(
                            (subcategory) => subcategory.status === 1
                          );

                        return (
                          <li className="opened-sub" key={category.id}>
                            <NavLink
                              to={`/solution/${category.slug}`}
                              onClick={() => {
                                if (category && category.id) {
                                  props.dispatch({
                                    type: "SELECT_CATEGORY",
                                    payload: category,
                                  });

                                  if (category.sub && category.sub.length > 0) {
                                    props.dispatch({
                                      type: "SELECT_SUBCATEGORY",
                                      payload: category.sub[0],
                                    });
                                  } else {
                                    props.dispatch({
                                      type: "SELECT_SUBCATEGORY",
                                      payload: category,
                                    });
                                  }
                                }
                              }}
                            >
                              {category.name}
                              {hasActiveSubCategories ? (
                                <span className="icon-chevron-down"></span>
                              ) : null}
                            </NavLink>
                            {hasActiveSubCategories ? (
                              <ul className="dropdown-menu2">
                                {category.sub.map((subcategory) => {
                                  if (subcategory.status === 1) {
                                    return (
                                      <li key={subcategory.id}>
                                        <NavLink
                                          to={`/solution/${subcategory.slug}`}
                                          state={{ slug: subcategory.slug }}
                                          onClick={() => {
                                            if (
                                              category.sub &&
                                              category.sub.length > 0
                                            ) {
                                              handleSubcategoryChange(
                                                subcategory
                                              );
                                            } else {
                                              handleSubcategoryChange(null);
                                            }
                                          }}
                                        >
                                          {subcategory.name}
                                        </NavLink>
                                      </li>
                                    );
                                  }
                                  return null;
                                })}
                              </ul>
                            ) : null}
                          </li>
                        );
                      }
                      return null;
                    })}
                </ul>
              </li>
              <li className="opened">
                <p>
                  {props.language === "az"
                    ? "Layihə"
                    : props.language === "en"
                    ? "Project"
                    : props.language === "ru"
                    ? "Проект"
                    : ""}
                  <span className="icon-chevron-down"></span>
                </p>
                <ul className="dropdown-menu1">
                  {projectCategory &&
                    projectCategory.map((category) => {
                      if (category.status === 1) {
                        const hasActiveSubCategories =
                          category.sub &&
                          category.sub.some(
                            (subcategory) => subcategory.status === 1
                          );

                        return (
                          <li className="opened-sub" key={category.id}>
                            <NavLink
                              to={`/project/${category.slug}`}
                              onClick={() => {
                                if (category && category.id) {
                                  props.dispatch({
                                    type: "SELECT_CATEGORY",
                                    payload: category,
                                  });

                                  if (category.sub && category.sub.length > 0) {
                                    props.dispatch({
                                      type: "SELECT_SUBCATEGORY",
                                      payload: category.sub[0],
                                    });
                                  } else {
                                    props.dispatch({
                                      type: "SELECT_SUBCATEGORY",
                                      payload: category,
                                    });
                                  }
                                }
                              }}
                            >
                              {category.name}
                              {hasActiveSubCategories ? (
                                <span className="icon-chevron-down"></span>
                              ) : null}
                            </NavLink>
                            {hasActiveSubCategories ? (
                              <ul className="dropdown-menu2">
                                {category.sub.map((subcategory) => {
                                  if (subcategory.status === 1) {
                                    return (
                                      <li key={subcategory.id}>
                                        <NavLink
                                          to={`/project/${subcategory.slug}`}
                                          state={{ slug: subcategory.slug }}
                                          onClick={() => {
                                            if (
                                              category.sub &&
                                              category.sub.length > 0
                                            ) {
                                              handleSubcategoryChange(
                                                subcategory
                                              );
                                            } else {
                                              handleSubcategoryChange(null);
                                            }
                                          }}
                                        >
                                          {subcategory.name}
                                        </NavLink>
                                      </li>
                                    );
                                  }
                                  return null;
                                })}
                              </ul>
                            ) : null}
                          </li>
                        );
                      }
                      return null;
                    })}
                </ul>
              </li>
              <li className="opened">
                <p>
                  {props.language === "az"
                    ? "Xidmət"
                    : props.language === "en"
                    ? "Service"
                    : props.language === "ru"
                    ? "Сервис"
                    : ""}
                  <span className="icon-chevron-down"></span>
                </p>

                <ul className="dropdown-menu1">
                  {serviceCategory &&
                    serviceCategory.map((category) => {
                      if (category.status === 1) {
                        const hasActiveSubCategories =
                          category.sub &&
                          category.sub.some(
                            (subcategory) => subcategory.status === 1
                          );

                        return (
                          <li className="opened-sub" key={category.id}>
                            <NavLink
                              to={`/service/${category.slug}`}
                              onClick={() => {
                                if (category && category.id) {
                                  props.dispatch({
                                    type: "SELECT_CATEGORY",
                                    payload: category,
                                  });

                                  if (category.sub && category.sub.length > 0) {
                                    props.dispatch({
                                      type: "SELECT_SUBCATEGORY",
                                      payload: category.sub[0],
                                    });
                                  } else {
                                    props.dispatch({
                                      type: "SELECT_SUBCATEGORY",
                                      payload: category,
                                    });
                                  }
                                }
                              }}
                            >
                              {category.name}
                              {hasActiveSubCategories ? (
                                <span className="icon-chevron-down"></span>
                              ) : null}
                            </NavLink>
                            {hasActiveSubCategories ? (
                              <ul className="dropdown-menu2">
                                {category.sub.map((subcategory) => {
                                  if (subcategory.status === 1) {
                                    return (
                                      <li key={subcategory.id}>
                                        <NavLink
                                          to={`/service/${subcategory.slug}`}
                                          state={{ slug: subcategory.slug }}
                                          onClick={() => {
                                            if (
                                              category.sub &&
                                              category.sub.length > 0
                                            ) {
                                              handleSubcategoryChange(
                                                subcategory
                                              );
                                            } else {
                                              handleSubcategoryChange(null);
                                            }
                                          }}
                                        >
                                          {subcategory.name}
                                        </NavLink>
                                      </li>
                                    );
                                  }
                                  return null;
                                })}
                              </ul>
                            ) : null}
                          </li>
                        );
                      }
                      return null;
                    })}
                </ul>
              </li>
              <li>
                <NavLink to="/news">
                  {props.language === "az"
                    ? "Xəbərlər"
                    : props.language === "en"
                    ? "News"
                    : props.language === "ru"
                    ? "Новости"
                    : ""}
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact">
                  {props.language === "az"
                    ? "Əlaqə"
                    : props.language === "en"
                    ? "Contact"
                    : props.language === "ru"
                    ? "Контакт"
                    : ""}
                </NavLink>
              </li>
            </ul>
            <div
              className="header__mobile"
              onClick={() =>
                props.dispatch({
                  type: "MOBILE_MENU",
                  payload: true,
                })
              }
            >
              <i className="fa-solid fa-bars"></i>
            </div>
            <div className="header__operations">
              <div className="header__language">
                <Dropdown show={showDropdown} onToggle={handleToggle}>
                  <Dropdown.Toggle variant="secondary" id="language-dropdown">
                    {selectedLanguage ? selectedLanguage.label : props.language}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {languages.map((language) => (
                      <Dropdown.Item
                        key={language.id}
                        eventKey={language.value}
                        onClick={() => {
                          handleLanguageSelect(language.value);
                          props.dispatch({
                            type: "SWITCH_LANGUAGE",
                            payload: language.value,
                          });
                        }}
                      >
                        {language.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div
                className="header__search"
                onClick={() => {
                  handleHeaderSearch();
                }}
              >
                <span
                  className={headerSearch ? "icon-chevron-down" : "icon-search"}
                ></span>
              </div>
              <div
                className={
                  headerSearch
                    ? "header__search-input show"
                    : "header__search-input"
                }
              >
                <div
                  className={
                    headerSearch
                      ? "header__search-input show"
                      : "header__search-input"
                  }
                >
                  <form action={`/search?key=${searchText}`} method="get">
                    <input
                      type="text"
                      name="key"
                      id=""
                      placeholder={
                        props.language === "az"
                          ? "Axtarış"
                          : props.language === "en"
                          ? "Search"
                          : props.language === "ru"
                          ? "Поиск"
                          : ""
                      }
                      value={searchText}
                      onChange={handleSearchInputChange}
                    />
                    <button type="submit">
                      <span className="icon-search"></span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedCategory: state.selectedCategory,
    language: state.language,
  };
};

export default connect(mapStateToProps)(Header);
