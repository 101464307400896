import React from "react";
import { connect } from "react-redux";
import { Breadcrumb } from "react-bootstrap";

function MyBreadcrumb({ paths, language }) {
  const lastPath = paths[paths.length - 1];
  window.localStorage.setItem("language", language);

  return (
    <div className="breadcrumb__top">
      <div className="container">
        <h2 className="breadcrumb__title">{lastPath.name}</h2>

        <Breadcrumb>
          <Breadcrumb.Item href="/">
            {language === "az"
              ? "Əsas səhifə"
              : language === "en"
              ? "Home Page"
              : language === "ru"
              ? "Главная страница"
              : ""}
          </Breadcrumb.Item>
          {paths.map((path, index) => (
            <Breadcrumb.Item
              key={index}
              href={path.link}
              active={index === paths.length - 1}
            >
              {path.name}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(MyBreadcrumb);
