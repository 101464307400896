import React from "react";

const ThumbnailCarousel = ({
  details,
  activeIndex,
  thumbnailStartIndex,
  imagesArray,
  handleSelect,
  prevSlide,
  nextSlide,
}) => {
  return (
    <div className="thumbnail-container">
      <button
        className={`arrow prev ${thumbnailStartIndex === 0 ? "disabled" : ""}`}
        onClick={prevSlide}
        disabled={thumbnailStartIndex === 0}
      >
        &lt;
      </button>
      <div className="thumbnail-carousel">
        {details.images !== null && details.images.length > 0 ? (
          imagesArray
            .slice(thumbnailStartIndex, thumbnailStartIndex + 4)
            .map((image, index) => (
              <img
                key={index}
                className={`thumbnail-image ${
                  activeIndex === index + thumbnailStartIndex ? "active" : ""
                }`}
                src={image}
                alt={`Thumbnail ${index + 1}`}
                onClick={() => handleSelect(index + thumbnailStartIndex)}
              />
            ))
        ) : (
          <div className="alternative-image-small">
            <img src={details.image} alt="Alternative Image" />
          </div>
        )}
      </div>

      <button
        className={`arrow next ${
          thumbnailStartIndex ===
          (details.images ? details.images.length : 0) - 4
            ? "disabled"
            : ""
        }`}
        onClick={nextSlide}
        disabled={
          thumbnailStartIndex ===
          (details.images ? details.images.length : 0) - 4
        }
      >
        &gt;
      </button>
    </div>
  );
};

export default ThumbnailCarousel;
