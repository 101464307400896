import "./App.css";
import "./css/iconfonts.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MainPage from "./pages/MainPage";
import CollectionProduct from "./pages/CollectionProduct";
import CollectionSolution from "./pages/CollectionSolution";
import CollectionProject from "./pages/CollectionProject";
import CollectionService from "./pages/CollectionService";
import DetailsProduct from "./pages/DetailsProduct";
import AboutUs from "./pages/AboutUs";
import News from "./pages/News";
import Article from "./pages/Article";
import ContactUs from "./pages/ContactUs";
import DetailsSolution from "./pages/DetailsSolution";
import DetailsService from "./pages/DetailsService";
import DetailsProject from "./pages/DetailsProject";
import Search from "./pages/Search";

function App() {
  let loc = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [loc.pathname]);

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/product/:slug" element={<CollectionProduct />} />
        <Route path="/product" element={<CollectionProduct />} />
        <Route path="/solution/:slug" element={<CollectionSolution />} />
        <Route path="/solution" element={<CollectionSolution />} />
        <Route path="/project/:slug" element={<CollectionProject />} />
        <Route path="/project" element={<CollectionProject />} />
        <Route path="/service/:slug" element={<CollectionService />} />
        <Route path="/service" element={<CollectionService />} />
        <Route path="/product-details/:slug" element={<DetailsProduct />} />
        <Route path="/solution-details/:slug" element={<DetailsSolution />} />
        <Route path="/project-details/:slug" element={<DetailsProject />} />
        <Route path="/service-details/:slug" element={<DetailsService />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/news" element={<News />} />
        {/* <Route path="/article" element={<Article />} /> */}
        <Route path="/article/:slug" element={<Article />} />
        <Route path="/contact" element={<ContactUs />} />
        {/* <Route path="/details/:id" element={<Details />} /> */}
        {/* <Route path="*" element={<NotFound />} /> */}
        <Route path="/search" element={<Search />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
