import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import * as consts from "../consts/Consts";

function Footer(props) {
  const [getData, setGetData] = useState(null);
  const apiToken = "3GhRJi7Z4uBvTnf2yQPcM5Ys8UeAxLk0WdFVX9vNwQ6zPbKuV";
  const apiUrl = `${consts.API_URL}/contact`;
  window.localStorage.setItem("language", props.language);
  useEffect(() => {
    const headers = {
      "api-token": apiToken,
      "Accept-Language": props.language,
      "Content-Type": "application/json",
    };

    fetch(apiUrl, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setGetData(data.data);
      })
      .catch((error) => {
        console.error("Ошибка при получении данных:", error);
      });
  }, [props.language]);

  return (
    <footer className="footer">
      <div className="footer__top">
        <div className="container">
          {getData && (
            <div className="footer__row row-3">
              <div className="footer__col col-3">
                <div className="footer__item">
                  <h5 className="footer__title">Address</h5>
                  <div className="footer__content">
                    <ul className="footer__address">
                      <li>
                        <span className="icon-location-dot"></span>
                        {getData.address}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer__col col-3">
                <div className="footer__item">
                  <h5 className="footer__title">Follow</h5>
                  <div className="footer__content">
                    <ul className="footer__social">
                      <li>
                        <NavLink to={getData.facebook}>
                          <span className="icon-facebook"></span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={getData.instagram}>
                          <span className="icon-instagram"></span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={getData.youtube}>
                          <span className="icon-youtube"></span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer__col col-3">
                <div className="footer__item">
                  <h5 className="footer__title">Contact</h5>
                  <div className="footer__content">
                    <ul className="footer__contact">
                      <li>
                        <NavLink to={`tel:${getData.phone}`}>
                          <span className="icon-phone"></span>
                          {getData.phone}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={`mailto:${getData.email}`}>
                          <span className="icon-email "></span>
                          {getData.email}
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="footer__bottom">
        <div className="container">
          <div className="footer__copyrights">@2023, Park Plyus Copyrights</div>
        </div>
      </div>
    </footer>
  );
}

const t = (a) => a;
export default connect(t)(Footer);
